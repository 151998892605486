import request from '@/http.js'

/*****************************基本配置*****************************/
/**
 * @description 配置管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function configAllDataApi (param) {
    return request.post(
        '/admin.systemConfig/index',
        param
    )
}

/**
 * @description 保存配置 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function configSaveDataApi (param) {
    return request.post(
        '/admin.systemConfig/listSave',
        param
    )
}
/*****************************充值配置管理*****************************/
/**
 * @description 充值配置 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function reChargeListApi (param) {
    return request.post(
        '/admin.systemUserRecharge/index',
        param
    )
}

/**
 * @description 充值配置 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function reChargeSaveApi (param) {
    return request.post(
        '/admin.systemUserRecharge/save',
        param
    )
}

/**
 * @description 充值配置 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function reChargeDeleteApi (param) {
    return request.post(
        '/admin.systemUserRecharge/delete',
        param
    )
}

/**
 * @description 充值配置 -- 停用/启用
 * @param {Object} param data {Object} 传值参数
 */
 export function reChargeSetupApi (param) {
    return request.post(
        '/admin.systemUserRecharge/status',
        param
    )
}


/*****************************会员等级*****************************/
/**
 * @description 会员等级 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function memberLevelListApi (param) {
    return request.post(
        '/admin.systemUserLevel/index',
        param
    )
}
/**
 * @description 会员等级 -- 编辑
 * @param {Object} param data {Object} 传值参数
 */
 export function memberLevelEditApi (param) {
    return request.post(
        '/admin.systemUserLevel/save',
        param
    )
}

/**
 * @description 会员等级 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function memberLevelDeleteApi (param) {
    return request.post(
        '/admin.systemUserLevel/delete',
        param
    )
}

/*****************************优惠券管理*****************************/
/**
 * @description 优惠券 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function couponListApi (param) {
    return request.post(
        '/admin.discount/index',
        param
    )
}

/**
 * @description 优惠券 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function couponSaveApi (param) {
    return request.post(
        '/admin.discount/save',
        param
    )
}

/**
 * @description 优惠券 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function couponDeleteApi (param) {
    return request.post(
        '/admin.discount/delete',
        param
    )
}

/**
 * @description 优惠券 -- 停用/启用
 * @param {Object} param data {Object} 传值参数
 */
 export function couponSetupApi (param) {
    return request.post(
        '/admin.discount/status',
        param
    )
}

/*****************************banner管理*****************************/
/**
 * @description banner -- 编辑
 * @param {Object} param data {Object} 传值参数
 */
 export function configBannerSaveApi (param) {
    return request.post(
        '/admin.banner/save',
        param
    )
}

/**
 * @description banner -- 查看
 * @param {Object} param data {Object} 传值参数
 */
 export function configBannerReadApi (param) {
    return request.post(
        '/admin.banner/read',
        param
    )
}

/**
 * @description banner -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function configBannerListApi (param) {
    return request.post(
        '/admin.banner/index',
        param
    )
}

/*****************************客服管理*****************************/
/**
 * @description 客服 -- 详情
 * @param {Object} param data {Object} 传值参数
 */
 export function serviceReadApi (param) {
    return request.post(
        '/admin.systemService/read',
        param
    )
}

/**
 * @description 客服 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function serviceSaveApi (param) {
    return request.post(
        '/admin.systemService/save',
        param
    )
}

/*****************************公告管理*****************************/
/**
 * @description 公告  -- 详情
 * @param {Object} param data {Object} 传值参数
 */
 export function noticeReadApi (param) {
    return request.post(
        '/admin.systemConfig/noticeMsg',
        param
    )
}

/**
 * @description 公告 -- 保存
 * @param {Object} param data {Object} 传值参数
 */
 export function noticeSaveApi (param) {
    return request.post(
        '/admin.systemConfig/noticeMsgSave',
        param
    )
}

/*****************************企业微信*****************************/
/**
 * @description 直播信息  -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function wechatListApi (param) {
    return request.post(
        '/admin.productLive/index',
        param
    )
}

/*****************************活动管理*****************************/
/**
 * @description 活动  -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function activeListApi (param) {
    return request.post(
        '/activity/getList',
        param
    )
}
/**
 * @description 删除活动
 * @param {Object} param data {Object} 传值参数
 */
 export function deleteActiveApi (param) {
    return request.post(
        '/activity/delete',
        param
    )
}
/**
 * @description 保存活动
 * @param {Object} param data {Object} 传值参数
 */
 export function activeSaveApi (param) {
    return request.post(
        '/activity/edit',
        param
    )
}
/**
 * @description 活动详情
 * @param {Object} param data {Object} 传值参数
 */
 export function activeReadApi (param) {
    return request.post(
        '/activity/read',
        param
    )
}


